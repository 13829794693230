<template>
    <div class="home">
        <div class="header">
            <div class="header_body">
                <div class="header_up">
                    <a href="https://lezione-online.it" target="blank"><img src="../assets/lezione_online.png"></a>
                    <button class="button" @click="logout()">Logout</button>
                </div>
            </div>
            <div class="header_bottom">
                <img src="../assets/uni.png" alt="Università">
                <div>
                <p>
                    XXXXXXXXXXXXXXXX<br>
                    XXXXXXXXXXXXXXXX<br>
                    C.F.: XXXXXXXXXXXXXXXX<br>
                    P.IVA: XXXXXXXXXXXXXXXX
                </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Header',

    methods: {
        logout(){
            sessionStorage.removeItem('token')
            sessionStorage.removeItem('password')
            window.location.reload()
        }
    },
}

</script>

<style scoped>
* {
    margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.header_body {
    border-top: 1px  solid rgba(0,0,0,.08);
    border-bottom: 1px solid rgba(0,0,0,.08);
}

.header_up {
    width: 1300px;
	margin: 0 auto;
    display: flex;
}
.header_up a {
    flex: 1;
}

.header_up img {
    max-height: 48px;
    width: auto;
    display: flex;
    vertical-align: middle;
    margin: 10px 0px 10px 20px;
}

.header_bottom {
    padding: 40px 0 60px 25px;
    display: flex;
    align-items: center;
    width: 1300px;
	margin: 0 auto;
    text-align: left;    
}

.header_bottom img {
    max-height: 125px;
    width: auto;
}

.header_bottom span {
    font-weight: bold;
    font-size: 25px;
    padding-bottom: 15px;
}

.header_bottom p {
    padding: 0 0 0 80px;
    line-height: 22px;
}

button {
    align-self: center;
    padding: 10px 25px;
    font-size: 18px;
}

</style>