<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  created () {
    document.title = "Uni | Lezione-online"
  }
}
</script>


<style lang="scss">

* {
  font-family: 'Open Sans', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
}

.button {
  background-color: #009DD8;
  border: 2px solid #009DD8;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 18px;
  padding: 10px 26px;
  text-decoration: none;
}
.button:hover {
  background-color:#ffffff;
  color: #009DD8;
}

.button:active {
  position:relative;
  top:1px;
}


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>


