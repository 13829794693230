<template>
    <div class="registration">
        <form action="" method="post" class="registration_form">

            <h3>Dettagli account</h3>

            <div class="input_mail">
                <input :value='mailEntered' type="email" name="email" id="email" disabled>
            </div>

            <div class="input_name">
                <div class="name">
                    <input type="text" v-model="first_name" id="nome" placeholder="Nome" name="nome" required>
                </div>
                <div class="surname">
                    <input type="text" v-model="last_name" id="cognome" placeholder="Cognome" name="cognome" required>
                </div>
            </div>
            <div class="input_pwd">
                <div class="pwd">
                    <input type="password" v-model="test_password" placeholder="Inserisci la password" name="password" required>
                </div>
                <div class="pwd_conf">
                    <input type="password" v-model="password" placeholder="Conferma la password" name="password" required>
                </div>
            </div>
        </form>
        <div class="button_reg">
            <button class="button" @click="postData()" :disabled="test_password !== password || test_password == null">Registra nuovo utente</button>
        </div>
        <span>{{reg_error}}</span>
    </div>
</template>


<script>

export default {
    name: 'Registration',

    data() {
        return {
            email : null,
            first_name : null,
            last_name : null,
            test_password : null,
            password : null,
            reg_error : "",
            responseId : null
        }
    },

    props : ['mailEntered'],

    mounted() {
        this.email = this.mailEntered
    },

    methods: {
        postData(){
            return fetch('https://uni-core.lezione-online.it/api/users/new', {
                    method: 'POST',
                    headers: {
                        'Accept': 'text/plain',
                        'Content-Type': 'Application/json',
                        'Authorization' : 'Bearer ' + this.$store.state.user.token
                    },
                    body: JSON.stringify({
                        email : this.email,
                        first_name : this.first_name,
                        last_name : this.last_name,
                        password : this.password
                    })
                })
                .then((res) => res.json()) 
                .then(obj => {
                
                if (obj.status !== 'ok' || obj.data == -1) {
                    this.reg_error = "Si é verificato un errore."
                } 
                this.responseId = obj.data
                this.$emit('registered')
            })
        }
    },
    
}

</script>


<style scoped>

.registration {
    margin: 0 auto;
    max-width: 800px;
    padding-top: 20px;
}

.registration_form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: self-start;
}

.registration_form .input_mail {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 20px;
}

.registration_form .input_name, .registration_form .input_pwd {
    display: flex;
    align-content: space-between;
    width: 100%;
    
}

.registration_form .input_name .name, .registration_form .input_pwd .pwd {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-right: 20px;
}


.registration_form .input_name .surname, .registration_form .input_pwd .pwd_conf {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-bottom: 20px;
}

.registration input {
    height: 36px;
    padding-left: 30px;
    font-size: 20px;
}

.registration .button_reg {
    display: flex;
    justify-content: flex-end;
}

button:disabled,
button[disabled]{
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
}

</style>