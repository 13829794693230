<template>
  <div class="home">    
    <Header />
    <Navbar />
    <Courses v-if="$store.state.activetab == 1" /> 
    <Users v-else-if="$store.state.activetab == 2" />
    <Setup v-else-if="$store.state.activetab == 3" />
    <Footer />
  </div>
</template>


<script>
// @ is an alias to /src
import Header from  '@/components/header.vue'
import Navbar from '@/components/navbar.vue'
import Courses from '@/components/courses.vue'
import Users from '@/components/users.vue'
import Setup from '@/components/setup.vue'
import Footer from '@/components/footer.vue'


export default {
  name: 'Home',
  
  components: {
    Header,
    Navbar,
    Courses,
    Users,
    Setup,
    Footer
  },
  created (){

    this.$store.dispatch('fetchCourses')
  },
    beforeDestroy() {
      console.log('destroying')
      sessionStorage.removeItem('token')
      sessionStorage.removeItem('password')
      window.location.reload()
  }
}

</script>

<style scoped>

</style>