import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    courses: [],

    activetab: 1,

    user: {
      username: sessionStorage.getItem('username'),
      token: sessionStorage.getItem('token'),
    }
  },

  getters:{
    getCourseByID(state, id) {
      let filtered = state.courses.filter(id)
      return filtered
    },
    getCourseByTitle: (state) => (title) => {
      return state.courses.find(course => course.title === title)
    }
  },

  mutations: {
    setUsername(state, val) {
      state.user.username = val
    },
    setToken(state, val) {
      state.user.token = val
    },
    setCourses(state, val) {
      state.courses = val
    }
  },
  actions: {
    fetchCourses({commit, state}) {
      fetch ('https://uni-core.lezione-online.it/api/courses', {
        method: 'GET',
        headers: {
          'Accept': 'text/plain',
          'Content-Type': 'Application/json',
          'Authorization' : 'Bearer ' + state.user.token
        },
      })
      .then((res) => res.json()) 
      .then(obj => {
        if (obj.status !== 'ok') {
          console.log("Errore token")
          sessionStorage.removeItem('token')
          sessionStorage.removeItem('password')
          window.location.reload()
          return
        }
        commit('setCourses', obj.data )
      })
      
    }
  },
  modules: {
  }
})
