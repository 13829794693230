<template>
    <div id="main">
        <nav >
            <ul>
                <li><a :class="{ active: $store.state.activetab == 1 }" href="#" @click="$store.state.activetab = 1">CORSI</a></li>
                <li><a :class="{ active: $store.state.activetab == 2 }" href="#" @click="$store.state.activetab = 2">UTENTI</a></li>
                <li><a :class="{ active: $store.state.activetab == 3 }" href="#" @click="$store.state.activetab = 3">IMPOSTAZIONI</a></li>
            </ul>
        </nav>
    </div>
</template>

<script>

export default {
    name: 'Navbar',
    
}


</script>

<style scoped>

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

#main{
    border-top: 1px  solid rgba(0,0,0,.08);
    border-bottom: 1px solid rgba(0,0,0,.08);
}

nav {
    max-width: 1300px;
    height: 40px;
    margin: 0 auto;
    text-align: left;
    font-size: 20px;
}

nav li a {
    letter-spacing: 1px;
    color: #444;
    line-height: 40px;
    font-weight: bold;
    text-decoration: none;
}

nav ul {
    list-style: none;
}

nav ul li {
    display: inline-block;
    padding: 0 20px;
}

nav ul li a  {
    transition: all 0.3s ease 0s;
}

nav ul li a:hover {
    color: #00ccff;
}

nav ul li a.active {
    color: #00ccff;
}

nav ul li a:active {
    color: #00ccff;
    border-bottom: 2px solid;
    transition: none;
    padding-bottom: 1px;
}


</style>