<template>
    <div class="excelCourses">
        
        <download-excel2
            :data="report"
            :fields="returnFields()"
            worksheet="Report_studenti"
            :name="`Report_studenti.xls`">

            <button class="button">Scarica report</button>
        </download-excel2>

    </div>
</template>

<script>

import JsonExcel from 'vue-json-excel'

export default {
    name: 'ExcelCourses',
    
    components: {
        'downloadExcel2' : JsonExcel
    },

    data() {
        return {
            report : [],
            
        }
    },
    methods: {

        returnFields() {
            
            const fields = {

                "Studente": {
                    callback: (value) => {
                        return `${value.name}`
                    }
                },

                "Email": {
                    callback: (value) => {
                        return `${value.email.toLowerCase()}`
                    }
                },

                "Corso" : {
                    callback: (value) => {
                        return `${value.course}`
                    }
                },

                "Inizio": {
                    callback: (value) => {
                        if(value.start_date != 'false' && value.start_date != false) {
                            return `${value.start_date}`
                        } else {
                            return ''
                        }
                    }
                },

                "Progresso": {
                    callback: (value) => {
                        return `${Math.round(value.progress)}`
                    }
                },

                "Completamento": {
                    callback: (value) => {
                        if(value.completion_date != 'false' && value.completion_date != false) {
                            return `${value.completion_date}`
                        } else {
                            return ''
                        }
                    }
                },

                "Punteggio": {
                    callback: (value) => {
                        if(value.quiz_score != 'false' && value.quiz_score != false) {
                            return `${value.quiz_score}`
                        } else {
                            return ''
                        }
                    }
                },
            }
            return fields
        }
    },
    mounted() {
        fetch ('https://uni-core.lezione-online.it/api/report', {
            method: 'GET',
            headers: {
                'Accept': 'text/plain',
                'Content-Type': 'Application/json',
                'Authorization' : 'Bearer ' + this.$store.state.user.token
            },
        })
        .then((res) => res.json()) 
        .then(obj => {
            console.log(obj)
            if (obj.status !== 'ok') {
                console.log("Errore")
                return
            }
            this.report = obj.data
        })
    }
}
</script>

<style scoped>

.excelCourses {
    align-self: center;
}

</style>