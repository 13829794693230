<template>
    <div class="footer">
        <div class="footer_bottom_section">
            Web Lab Group SRLS - Strada dell’Osteria, 91/B - 06050 – Collazzone, Perugia - ©2021 lezione-online
            - p.iva 03487630547
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style scoped>

.footer_bottom_section {
    background: #232b2d;
    text-align: center;
    padding: 15px;
    color: #dddddd;
    font-size: 13px;
    margin-top: 80px;
}

.footer {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
}   


</style>