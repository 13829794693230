<template>
    <div class="course_user">
        <div class="form_container">
            <h1>Cerca Utente</h1>
            <form action="" method="post" class="form_email">
                <div class="input_email">
                    <input type="email" v-model="email" name="email" id="email" placeholder="Inserisci email" required>
                </div>
                <div class="input_email">
                    <input id="cercaUtenteBtn" class="button" type="submit" value="Cerca" @click="cercaUtente">
                </div>
            </form>

            <div id="user_unreg" class="user_reg" v-if="searchStarted && !userFound && !showRegistration" >
                <h3>{{variabile}}</h3>
                <button class="button" v-if="showButton" @click="showRegistration=true">Registra utente</button>
            </div>
            
            <div id="user_reg" v-if="searchStarted && userFound">
                <h3>Utente registrato</h3>
                <form  method="post" class="form_select">
                    <div class="input_course">
                        <input v-model="selectedCourse" autocomplete="off" list="course_list" type="text" 
                        name="course_select" id="course_select" placeholder="Seleziona il corso da aggiungere all'utente"  required>
                    </div>
                    <datalist id="course_list">
                        <option v-for="course in $store.state.courses" :key="course.title" :value="course.title"></option>
                    </datalist>
                    <div class="input_course">
                        <input class="button" type="submit" value="Aggiungi corso" @click="addCourse">
                    </div>
                </form>
            </div>
            <div v-if="userAddCourse && userFound && userAddCourse && !selectedCourse">
                <h3>{{addedCourse}} è stato aggiunto correttamente all'utente</h3>
            </div>
        </div>
        <Registration :mail-entered="email" v-if="searchStarted && !userFound && showRegistration" @registered="registrationCompleted" />
    </div>

</template>


<script>
import Registration from "@/components/registration.vue"

export default {
    name: 'Users',

    components: {
        Registration

    },
    data() {
        return {
            email : null,
            testEmail : null,
            variabile : "",
            searchStarted : false,
            userFound : false,
            showRegistration : false,
            showButton : false,
            selectedCourse : null,
            userAddCourse : null,
            addedCourse : null
        }
    },

    methods: {
        registrationCompleted(){
            this.showRegistration = false
            document.getElementById('cercaUtenteBtn').click()
        },
        cercaUtente(e) {
            e.preventDefault()
            this.searchStarted = false
            this.userFound = false
            this.showRegistration = false
            fetch (`https://uni-core.lezione-online.it/api/users/exists/${this.email}`, {
                method: 'GET',
                headers: {
                    'Accept': 'text/plain',
                    'Content-Type': 'Application/json',
                    'Authorization' : 'Bearer ' + this.$store.state.user.token
                },
            })
            .then((res) => res.json()) 
            .then(obj => {
                
                if (obj.status !== 'ok') {
                    return
                }
                this.testEmail = obj.data
                this.switchComponents(obj.data)
            })
            
            this.searchStarted = true
        },
        switchComponents(value){
            if (value == 0) {
                this.showButton = true
                this.variabile = 'Utente non registrato'
            }
            else if (value == -1){
                this.variabile = 'Formato email non valido'
            }
            else if (value == -2){
                this.variabile = 'Inserisci una mail @uni.it'
            }
            else {
                this.userFound = true
            }
        },
        addCourse(e) {
            e.preventDefault()
            const course = this.$store.getters.getCourseByTitle(this.selectedCourse)
            fetch('https://uni-core.lezione-online.it/api/user/'+this.testEmail+'/addCourse', {
                method: 'POST',
                headers: {
                    'Authorization' : 'Bearer ' + this.$store.state.user.token,
                    'Accept': 'text/plain',
                    'Content-Type': 'Application/json'
                },
                body: JSON.stringify({
                        course_id : course.ID
                })
            })
            .then((res) => res.json()) 
            .then(obj => {
                if (obj.status !== 'ok') {
                    return
                }
                this.userAddCourse = obj.data
                this.addedCourse = this.selectedCourse
                this.selectedCourse = null

            })
        }
    },
    
}
</script>


<style scoped>

.course_user {
    padding-top: 50px;
    padding-bottom: 20px;
    max-width: 1300px;
    margin: 0 auto;
    min-height: calc(100vh - 535px);
}

.form_container {
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
}

.form_container h1, .form_container h3 {
    text-align: left;
    padding-bottom: 30px;
}

.course_user .form_email, .course_user .form_select {
    display: flex;
}

.input_email, .input_course {
    flex: 1;
    text-align: right;
}

#email {
    width: 430px;
    height: 36px;
    padding-left: 30px;
    font-size: 20px;
}

#course_select {
    width: 430px;
    height: 36px;
    padding-left: 30px;
    font-size: 20px;
}

.user_reg {
    display: flex;
    height: 42px;
    padding-top: 30px;
}

.user_reg h3 {
    margin: 0;
    flex: 1;
    align-self: center;
}

.form_container .input_email .button {
    padding: 10px 35px;
}



::-webkit-input-placeholder { 
    color: rgba(0, 0, 0, 0.5); 
    font-style:italic; 
} 
::-moz-input-placeholder { 
    color: rgba(0,0,0,0.5); 
    font-style:italic;
} 
::-ms-input-placeholder { 
    color: rgba(0,0,0,0.5); 
    font-style:italic;
}

</style>