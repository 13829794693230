<template>
    <div class="student_detail">
        <div class="row_detail">
            <div class="name">
                <p>{{studentData.name}}</p>
            </div>
            <div class="date_start">
                <span>{{startDate}}</span>
            </div>
            <div class="progress">
                <div class="progress_bar">
                    <div class="progress_bar_filled" v-bind:style="{width: this.studentData.progress + '%'}"></div>
                </div>
                <span>{{Math.floor(this.studentData.progress)}}%</span>
            </div>
            <div class="quiz_score">
                <font-awesome-icon v-if="studentData.quiz_score >= 80" :style="{color: 'green'}" icon="check" />
                <font-awesome-icon v-if="studentData.quiz_score < 80 && studentData.quiz_score != false" :style="{color: 'red'}" icon="times" />
                <span>{{ quizScore }}</span>
            </div>
        </div>
    </div>
</template>


<script>

export default {
    name: 'Students',

    props: ['studentData'],

    computed: {
        startDate: function() {
            if (this.studentData.start_date == false) {
                return "Non iniziato"
            }
            else {
                return this.studentData.start_date
            }
        },
        quizScore: function() {
            if (this.studentData.quiz_score == false) {
                return "Non tentato"
            }
            else {
                return `${this.studentData.quiz_score}/100`
            }
        }
    },
}
</script>


<style scoped>

.student_detail {
    width: 100%;

}

.row_detail {
    width: 1300px;
    margin: 0 auto;
    display: flex;
    text-align: left;
    border-bottom: 1px solid #00ccff;
    font-size: 19px;
}

.name {
    flex: 8;
}

.date_start {
    flex: 3;
    align-self: center;
    align-items: center;
}

.progress {
    flex: 4;
    align-self: center;
    display: flex;
    position: relative;
}

.progress_bar {
    width: 80%;
    height: 24px;
    background: #eee;
    border-radius: 3px;
}

.progress_bar_filled {
    height: 24px;
    border-radius: 3px;
    background: #009DD866;
}

.progress span {
    position: absolute;
    width: 80%;
    left: 0;
    top: 1px;
    font-size: 14px;
    text-align: center;
    font-weight: 600;
}

.quiz_score {
    display: flex;
    flex: 2;
    align-self: center;
    align-items: center;
}

.quiz_score svg {
    margin-right: 8px;
}

img {
    width: 25px;
    height: auto;
    padding-right: 10px;
}



</style>