<template>
    <div class="all_courses">
        <div v-if="!selectedCourseId" class="courseList" >
            <div class="courses">
                <div class="courses_header">
                    <h1>
                        Corsi attivi
                    </h1>
                    <excel-Courses/>
                </div>
            </div>
            <div class="container">
                <div v-for="course in $store.state.courses" :key="course.ID" @click="openCourse(course.ID, course.title)">
                    <Cards :course-data="course"></Cards>
                </div>
            </div>
        </div>
        <div class="courses_container" v-if="!!selectedCourseId">
            <button class="button" @click="selectedCourseId = null"><font-awesome-icon icon="arrow-left" />&nbsp; Torna ai corsi</button>
            <course-detail :course-id="selectedCourseId" :course-title="selectedCourseTitle"/>
        </div>
    </div>
</template>


<script>

    import Cards from '@/components/cards.vue'
    import courseDetail from '@/components/courseDetail.vue'
    import excelCourses from '@/components/excelCourses.vue'

    export default {
        name: 'Courses',
        components: {
            Cards,
            'excelCourses' : excelCourses,
            'course-detail': courseDetail
        },
        data() {
            return {
                selectedCourseId: null,
                selectedCourseTitle: null
            }
        },
        methods: {
            openCourse(courseID, courseTitle) {
                this.selectedCourseId = courseID
                this.selectedCourseTitle = courseTitle
            }
        }
    }

</script>



<style scoped>

    .courseList {
        min-height: calc(100vh - 465px);
    }

    .courses_container {
        display: inline-grid;
        padding: 30px 0;
        min-width: 1300px;
        min-height: calc(100vh - 525px);
    }

    .courses {
        max-width: 1300px;
        margin: 0 auto;
        padding-top: 50px;
    }

    .courses .courses_header {
        display: flex;
        padding: 0px 20px 50px 20px;
    }

    .courses .courses_header h1 {
        display: flex;
        flex: 1;
    }
    
    .container {
        max-width: 1300px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
    }

    .container .cards {
        display: flex;
        padding: 15px 0;
    }

    .container div {
        cursor: pointer;
    }
    
    .courses_container button {
        display: inline-block;
        background-color: #ffffff;
        color: #009DD8;
        border: 2px solid #009DD8;
        cursor: pointer;
        padding: 4px 14px;
        text-decoration: none;
        width: 181px;
        font-size: 18px;
        margin-left: auto;
    }

    .courses_container button:hover {
        background-color: #009DD8;
        color: #ffffff;
    }

</style>