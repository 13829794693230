<template>
    <div class="cards">
        <div class="cards_courses">
            <img :src="courseData.image" :alt="courseData.title">
            <h3>{{courseData.title}}</h3>
        </div>
    </div>
</template>


<script>

export default {
    name: 'Cards',
    
    props: ['courseData'],
    
}

</script>


<style scoped>

    .container .cards .cards_courses {
        padding: 0 25px;
    }
    
    .container .cards .cards_courses img {
        width: 210px;
    }

    .container .cards .cards_courses h3 {
        text-align: center;
        font-size: 15px;
        margin: 0;
        padding: 8px 0;
        max-width: 210px;
    }

</style>