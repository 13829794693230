<template>
    <div class="setup">
        
        <h1>Impostazioni generali</h1> 
        
        <h3>Dettagli account</h3>
        
        <div class="input_login">
            <div class="user">
                <input type="text" name="user_login" id="user_login" :placeholder="$store.state.user.username" disabled>
            </div>
            <div class="user_button">
                <button class="button"  @click="pwdMod()">Modifica la Password</button>
            </div>
        </div>

        <form method="post" class="account_details">

            <div class="new_password" v-if="changePwd">
                <h3 class="change_pwd">Cambia Password</h3>
                <div class="cont_old_pass">
                    <div class="old_pass">

                        <input type="password" v-model="oldPwd" name="old_pass" id="old_pass" placeholder="Inserisci la password corrente" required>
                        <h3 v-if="invalid_password">password non valida!</h3>
                        
                    </div>
                    
                </div>
                
                <div class="validation">
                    <div class="new_pwd">
                        <input type="password" v-model="new_pwd" id="new_pwd" placeholder="Nuova password" name="new_pwd" @click="pwd_verify()" required>
                    </div>
                    <div class="pwd_repeat">
                        <input type="password" v-model="pwd_repeat" id="pwd_repeat" placeholder="Ripeti la password" name="pwd_repeat" required>
                    </div>
                    
                </div>
                
                <div class="save_pwd">
                    <h3 class="change_pwd">{{message}}</h3>
                    <button class="button"  @click="change()" :disabled="new_pwd !== pwd_repeat || pwd_repeat == null">Salva aggiornamenti</button>
                </div>
            </div>
            
        </form>
    </div>
</template>

<script>

export default {
    name:'Setup',

    data() {
        return {
            changePwd : false,
            password : sessionStorage.getItem('password'),
            oldPwd : null,
            new_password : null,
            pwd_canged : false,
            invalid_password :null,
            new_pwd : null,
            pwd_repeat: null,
            message : ""
        }
    },
    
    methods: {
        pwdMod: function() {
            this.changePwd = !this.changePwd
        },
        change: function() {
            return fetch('https://uni-core.lezione-online.it/api/changePassword', {
                    method: 'POST',
                    headers: {
                        'Accept': 'text/plain',
                        'Content-Type': 'Application/json',
                        'Authorization' : 'Bearer ' + this.$store.state.user.token
                    },
                    body: JSON.stringify({
                        current_password : this.oldPwd,
                        new_password : this.new_password,
                    })
                })
                .then((res) => res.json()) 
                .then(obj => {
                
                if (obj.status !== 'ok' || obj.data == null) {
                    this.message = "Si é verificato un errore."
                } 
                this.message = "Aggiornamento password completato"
            })
        },
        pwd_verify: function() {
            if (this.oldPwd !== this.password) {
                return this.invalid_password = true
            }
            else {
                return this.invalid_password = false
            }
        }
    }
}

</script>

<style scoped>

.setup {
    margin: 0 auto;
    max-width: 800px;
    padding-top: 40px;
    padding-bottom: 20px;
    min-height: calc(100vh - 525px);
}

.input_login ,h3, h1 {
    display: flex
}

.account_details {
    width: 100%;
    align-items: self-start;
    padding-top: 10px;
}

.input_login .user {
    width: 50%;
    text-align: left;
    display: flex;
}

.input_login .user_button {
    width: 50%;
    text-align: right;
}

input {
    display: flex;
    height: 36px;
    padding-left: 30px;
    font-size: 20px;
}

.old_pass {
    display: flex;
    flex: 1;
}

#old_pass {
    flex: 1;
    max-width: 44%;
}

.new_password {
    padding-top: 30px;

}

.new_password .validation {
    padding-top: 20px;
    display: flex;
    width: 100%;
}

.new_pwd {
    display: flex;
}

.user input, .validation .new_pwd, .cont_old_pass, .new_pwd, #new_pwd {
    flex: 1;
}


.validation .pwd_repeat {
    flex: 1;
    padding-left: 30px;
}

#pwd_repeat {
    width: 90%;
}

.save_pwd {
    display: flex;
    padding-top: 20px;
}

.save_pwd h3, .old_pass h3{
    margin: 0;
    flex: 1;
    align-items: center;
}

.old_pass h3{
    justify-content: flex-end;
}

#user_login {
    max-width: 88%;
}

button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}


</style>