<template>
    <div class="course_datail">
        <div v-if="students_excel.length !== 0" class="title_container">
            <h1>{{courseTitle}}</h1>
            
            <form class="research-module">
                <input id="search" type="search" placeholder="Cerca studente" @click="filterClear" v-model="search">
                <input id="submit" type="submit" value="Cerca" @click="filteredList">
            </form>

            <download-excel
                :data="students_excel"
                :fields="fields"
                worksheet="My Worksheet"
                :name="`${courseTitle}.xls`"
            >
            <button class="button">Scarica report</button>
            </download-excel>
        </div>
        <div v-if="students.length !== 0" class="table_title">
            <h3 class="students">Studente</h3>
            <h3 class="date_start">Data inizio</h3>
            <h3 class="stats">Fruizione</h3>
            <h3 class="test">Test</h3>
        </div>

        <div v-if="students.length !== 0" class="students_container">
            <div v-for="student in students_search" :key="student.ID">
                <Students :student-data="student"></Students>
            </div>
        </div>
        <div v-if="students.length == 0">
            <h2>Non ci sono utenti in questo corso</h2>
        </div>
    </div>
</template>



<script>

    import Students from '@/components/students.vue'
    import JsonExcel from "vue-json-excel"

    export default {
        
        name:'courseDetail',
        props: ['courseId', 'courseTitle'],
        components: {
            Students,
            "downloadExcel": JsonExcel
        },
        data() {
            return {
                search:"",
                students: [],
                students_search: [],
                students_excel: [],
                fields: {
                    "ID": "ID",
                    "Name": "name",
                    "Email": "email",
                    "Inizio": "start_date",
                    "Progress": "progress",
                    "Score": "quiz_score"
                }
            }
        },

        mounted() {
            fetch (`https://uni-core.lezione-online.it/api/course/${this.courseId}/students`, {
                method: 'GET',
                headers: {
                    'Accept': 'text/plain',
                    'Content-Type': 'Application/json',
                    'Authorization' : 'Bearer ' + this.$store.state.user.token
                },
            })
            .then((res) => res.json()) 
            .then(obj => {
                console.log(obj)
                if (obj.status !== 'ok') {
                    console.log("Errore")
                    return
                }
                this.students = obj.data
                this.students_excel = obj.data
                this.students_search = obj.data

                this.students_excel.map(function(student) {
                    if(student['start_date'] == false) {
                        student['start_date'] = ''
                    }

                    if(student['quiz_score'] == false) {
                        student['quiz_score'] = ''
                    }

                    student['progress'] = Math.round(student['progress'])
                })
            })
        },

        methods: {
            filteredList(e) {
                e.preventDefault()
                this.students_search = this.students.filter(student => student.name.toLowerCase().includes(this.search.toLowerCase())) 
            },
            
            filterClear(e) {
                e.preventDefault()
                this.students_search = this.students
                this.search = ""
            }
        }
    }
    
</script>



<style scoped>

input[type="search" i] {
    padding-right: 5px;
}

#search:focus {
    outline:none;
}

.research-module {
    align-self: center;
    margin-right: 20px;
    overflow: hidden;
}

.research-module #search {
    border: 2px solid #DDD;
    color: rgb(0, 0, 0);
    float: left;
    font-size: 20px;
    box-sizing: border-box;
    height: 46px;
    padding-left: 10px;
    width: 300px;
}

.research-module #submit {
    background-color: #009DD8;
    border: 2px solid #009DD8;
    color: #FFF;
    cursor: pointer;
    height: 46px;
    float: right;
    font-size: 20px;
    padding: 0;
    width: 100px;
}

.research-module #submit:hover,
.research-module #submit:focus,
.research-module #submit:active {
    background-color: #ffffff;
    color: #009DD8;
    
}

.course_datail {
    min-height: calc(100vh - 558px);
    
}
.course_datail div{
    display: flex;
    max-width: 1300px;
    margin: 0 auto;
    text-align: left;
}

.title_container h1 {
    flex: 1;
}

.title_container button {
    align-self: center;
    text-align: center;
    font-size: 20px;
    max-height: 46px;
}

.table_title {
    font-size: 20px;
    padding-top: 40px;
}

.students {
    flex: 8;
}

.date_start {
    flex: 3;
}

.stats {
    flex: 4;
}

.test {
    flex: 2;
}

.students_container {
    flex-direction: column;
}

</style>