<template>
    <div class="login">
        <div class="header_body">
            <div class="header_up">
                <a href="https://lezione-online.it" target="blank"><img src="../assets/lezione_online.png"></a>
            </div>
        </div>
        <div class="container_auth">
            <img src="../assets/uni.png" alt="Università">
            <div class="auth">
                <input class="input_box" type="text" placeholder="Nome utente" name="username" id="username" v-model="username">
                <input class="input_box" type="password" placeholder="Password" name="password" id="password" v-model="password">
            </div>

            <button class="button" @click="login()">Login</button>
        </div>
        <span>{{error_msg}}</span>
    </div> 
</template>

<script>
export default {
    name: 'Login',
    data() {
        return {
            error_msg: "",
            username: sessionStorage.getItem('username'),
            password: sessionStorage.getItem('password')
        }
    },
    methods: {
        login() {
            sessionStorage.setItem('username', this.username)
            sessionStorage.setItem('password', this.password)
            fetch('https://uni-core.lezione-online.it/auth', {
                method: 'POST',
                headers: {
                    'Accept': 'text/plain',
                    'Content-Type': 'Application/json'
                },
                body: JSON.stringify({
                    username: this.username, 
                    password: this.password
                })
            })
            .then(res => {
                return res.text()
            })
            .then(token => {
                if (token) {
                    this.$store.commit('setToken', token)
                    this.$store.commit('setUsername', this.username)
                    sessionStorage.setItem('token', token)
                    window.location.href = '/'
                } else {
                    this.error_msg = "Si é verificato un errore."
                }
            })
        }
    },
    created() {
        window.addEventListener('keydown', (e) => {
            if (e.key == 'Enter') {
                this.login()
            }
        })
    }
}
</script>



<style scoped>

    .login {
        width: 100%;
        height: 100vh;
        background-image: url(../../src/assets/foto-sfondo-login.jpg);
        background-repeat: no-repeat;
        background-size: cover;
    }

    .header_body {
        border-top: 1px  solid rgba(0,0,0,.08);
        border-bottom: 1px solid rgba(0,0,0,.08);
    }

    .header_up {
        margin: 0 auto;
    }

    .header_up img {
        max-height: 60px;
        width: auto;
        display: flex;
        vertical-align: middle;
        margin: 10px 0px 10px 20px;
    }

    .auth {
        padding-top: 45px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .login .auth input {
        width: 350px;
        height: 40px;
        margin-bottom: 25px;
    }

    .button {
        width: 150px;
        margin-top: 20px;
    }

    .lost_pwd {
        padding: 20px;
    }

    .lost_pwd a {
        text-decoration: none;
    }

    .input_box {
        padding: 0 20px;
        border: 0;
        font-size: 18px;
    }

    .container_auth {
        height: 80%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .container_auth img {
        width: 240px;
    }

    ::-webkit-input-placeholder { 
        color: rgba(0, 0, 0, 0.6); 
        font-style:italic; 
        font-size: 18px;
    } 
    ::-moz-input-placeholder { 
        color: rgba(0,0,0,0.6); 
        font-style:italic;
    } 
    ::-ms-input-placeholder { 
        color: rgba(0,0,0,0.6); 
        font-style:italic;
    }

</style>